


















import Vue from 'vue';
import ImagePreview from "./ImagePreview.vue";
import bytes from "bytes";

export default Vue.extend({
	name: "ErredFile",
	props: [
		'file',
		'fileIndex',
		'errorMessage',
	],
	components: {
		ImagePreview,
	},
	methods: {
		getSize: function (file: MOxieFile) : string {
			return bytes(file.size);
		},
		deleteFile: function () : void {
			this.$store.commit('REMOVE_ERRED_FILE', this.file);
		},
	}
});
